@use "../../core/ui/styles/mixins/breakpoints" as *;
@use "../../core/ui/styles/functions/_calcrem.scss" as *;
@use "../../core/ui/styles/variables/base" as *;

.cdk-overlay-container {
  z-index: $cdk-overlay-container-z-index;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.dark-backdrop {
  background: rgb(0 0 0 / 80%);
}

.panel-from-right {
  width: 640px;
}

.panel-from-right-wider {
  width: 840px;
}
