@use "../functions/calcrem" as *;

/* main */
$main-padding-xs: 0 calcrem(8px) calcrem(60px) calcrem(8px);
$main-padding-mobile: 0 calcrem(16px) calcrem(60px) calcrem(16px);
$main-padding-tablet: 0 calcrem(24px) calcrem(80px) calcrem(24px);
$main-padding-desktop: 0 calcrem(48px) calcrem(120px) calcrem(48px);
$main-padding-widescreen: 0 calcrem(48px) calcrem(120px) calcrem(48px);
$main-padding-fullhd: 0 calcrem(48px) calcrem(120px) calcrem(48px);

/* header bar */
$header-padding-xs: 0 calcrem(8px);
$header-padding-mobile: 0 calcrem(16px);
$header-padding-tablet: 0 calcrem(24px);
$header-padding-desktop: 0 calcrem(32px);
$header-padding-widescreen: 0 calcrem(48px);
$header-padding-fullhd: 0 calcrem(48px);

/* steps */
$step-margin-xs: calcrem(16px);
$step-margin-mobile: calcrem(16px);
$step-margin-tablet: calcrem(24px);
$step-margin-desktop: calcrem(32px);
$step-margin-widescreen: calcrem(48px);
$step-margin-fullhd: calcrem(48px);

/* headlines */
$h1-margin-xs: calcrem(12px) 0 0 0;
$h1-margin-mobile: calcrem(12px) 0 0 0;
$h1-margin-tablet: calcrem(12px) 0 0 0;
$h1-margin-desktop: calcrem(16px) 0 0 0;
$h3-margin-xs: 0 0 calcrem(8px) 0;
$h3-margin-mobile: 0 0 calcrem(8px) 0;
$h3-margin-tablet: 0 0 calcrem(8px) 0;
$h3-margin-desktop: 0 0 calcrem(8px) 0;
$subheadline-margin-xs: calcrem(8px) 0 calcrem(8px) 0;
$subheadline-margin-mobile: calcrem(8px) 0 calcrem(8px) 0;
$subheadline-margin-tablet: calcrem(8px) 0 calcrem(8px) 0;
$subheadline-margin-desktop: calcrem(8px) 0 calcrem(8px) 0;
$h1-to-paragraph-padding: calcrem(8px);
$h2-to-paragraph-padding: calcrem(8px);
$h3-to-paragraph-padding: calcrem(4px);

/* content */
$content-padding-xs: calcrem(16px) 0;
$content-padding-mobile: calcrem(16px) 0;
$content-padding-tablet: calcrem(16px) 0;
$content-padding-desktop: calcrem(24px) 0;
$content-padding-widescreen: calcrem(24px) 0;
$content-padding-fullhd: calcrem(24px) 0;
$content-max-width: calcrem(576px);

/* cards */
$card-border-radius: calcrem(8px);
$card-margin-xs: calcrem(8px);
$card-margin-mobile: calcrem(16px);
$card-padding-small-mobile: calcrem(16px);
$card-padding-mobile: calcrem(16px);
$card-padding-tablet: calcrem(24px);
$card-padding-desktop: calcrem(40px) calcrem(60px);
$card-box-shadow: 0 4px 8px rgba(0 0 0 / 16%);
$card-box-shadow-muted: 0 4px 8px rgb(0 0 0 / 3%);

/* tiles */
$tile-border-radius: calcrem(4px);

/* inputs */
$input-border-radius: calcrem(4px);

/* buttons */
$button-border-radius: calcrem(4px);
$cta-border-radius: calcrem(4px);

/* grid */
$column-gap-xs: calcrem(8px);
$column-gap-mobile: calcrem(8px);
$column-gap-tablet: calcrem(16px);
$column-gap-desktop: calcrem(16px);
$column-gap-widescreen: calcrem(16px);
$column-gap-fullhd: calcrem(24px);
$row-gap-xs: calcrem(8px);
$row-gap-mobile: calcrem(8px);
$row-gap-tablet: calcrem(8px);
$row-gap-desktop: calcrem(8px);
$row-gap-widescreen: calcrem(8px);
$row-gap-fullhd: calcrem(8px);

/* drawer & dialog */
$drawer-border-radius: calcrem(16px);
$drawer-padding-xs: calcrem(24px);
$drawer-padding-mobile: calcrem(24px);
$drawer-padding-tablet: calcrem(24px);
$drawer-padding-desktop: calcrem(40px);
$drawer-padding-widescreen: calcrem(40px) calcrem(60px);
$drawer-padding-fullhd: calcrem(40px) calcrem(60px);
$drawer-button-gap-xs: calcrem(8px);
$drawer-button-gap-mobile: calcrem(16px);
$dialog-width: calcrem(704px);
$dialog-max-height: calc(100% - ($drawer-padding-mobile * 2));
$dialog-margin-xs: 0 calcrem(16px);
$dialog-margin-mobile: 0 calcrem(24px);
$dialog-margin-tablet: 0 calcrem(32px);
$cdk-overlay-container-z-index: 1010;
