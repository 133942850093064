@use "@angular/material" as mat;
@use "../../core/ui/styles/functions/calcrem" as *;
@use "../../core/ui/styles/variables/colors" as *;
@use "../../core/ui/styles/variables/fonts";

@include mat.core();

$mat-app-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$mat-app-accent: mat.m2-define-palette(mat.$m2-pink-palette, 200, 100, 400);
$mat-app-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $mat-app-primary,
      accent: $mat-app-accent
    )
  )
);

@include mat.badge-theme($mat-app-theme);
@include mat.button-theme($mat-app-theme);
@include mat.core-theme($mat-app-theme);
@include mat.datepicker-theme($mat-app-theme);
@include mat.form-field-theme($mat-app-theme);
@include mat.icon-theme($mat-app-theme);
@include mat.input-theme($mat-app-theme);
@include mat.select-theme($mat-app-theme);

/* custom styles for material components */

.mat-form-field-appearance-fill {
  .mat-form-field-flex {
    /* stylelint-disable-next-line declaration-no-important */
    padding: 1em 0.75em 0 1em !important;
  }

  .mat-form-field-infix {
    /* stylelint-disable-next-line declaration-no-important */
    padding: 0.45em 0 0.75em !important;
    border-top: 0.2em solid transparent;
  }

  .mat-form-field-label {
    margin-top: 0;
    color: $cg-anthracite-light-30;
  }

  .mat-input-element {
    /* stylelint-disable-next-line declaration-no-important */
    font-size: calcrem(16px) !important;
  }
}

.mat-form-field.mat-focused {
  .mat-form-field-label {
    color: var(--olb-primary-color);
  }

  .mat-form-field-ripple {
    background-color: var(--olb-primary-color);
  }
}

/* if label is floating (on top) */
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  margin-top: -0.5em;
  /* stylelint-disable-next-line declaration-no-important */
  font-weight: normal !important;
}
