/* media breakpoints */

/* on any change here, please change also the typescript breakpoints: {@link media-breakpoint.enum.ts} */

$small-mobile: 321px;
$mobile: 359px;
$tablet: 576px;
$desktop: 768px;
$widescreen: 992px;
$fullhd: 1200px;

@mixin small-mobile {
  @media screen and (max-width: $small-mobile - 1px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: $tablet - 1px) {
    @content;
  }
}

@mixin xs {
  @media screen and (max-width: $mobile) {
    @content;
  }
}

@mixin mobile-only {
  @media screen and (min-width: $mobile + 1) and (max-width: $tablet - 1) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: $tablet) {
    @content;
  }
}

@mixin tablet-only {
  @media screen and (min-width: $tablet) and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin touch {
  @media screen and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop) {
    @content;
  }
}

@mixin desktop-only {
  @media screen and (min-width: $desktop) and (max-width: $widescreen - 1px) {
    @content;
  }
}

@mixin until-widescreen {
  @media screen and (max-width: $widescreen - 1px) {
    @content;
  }
}

@mixin widescreen {
  @media screen and (min-width: $widescreen) {
    @content;
  }
}

@mixin widescreen-only {
  @media screen and (min-width: $widescreen) and (max-width: $fullhd - 1px) {
    @content;
  }
}

@mixin until-fullhd {
  @media screen and (max-width: $fullhd - 1px) {
    @content;
  }
}

@mixin fullhd {
  @media screen and (min-width: $fullhd) {
    @content;
  }
}

@mixin until-900 {
  @media screen and (max-width: $widescreen - 92px) {
    @content;
  }
}

@mixin above-900 {
  @media screen and (min-width: $widescreen - 91px) {
    @content;
  }
}
