@use "../../../core/ui/styles/functions/calcrem" as *;

/* TODO: check later if we need a base style or if it is better to place it somewhere else */

@layer base {
  /* TODO: some default,consent styles are exactly the same. remove them? */

  /* TODO: Should we continue to use this? Does not match figma token names, also bloat css */

  .typo {
    &-headline {
      @apply font-carglass-condensed font-normal;

      font-size: calcrem(56px);
      line-height: calcrem(56px);
    }

    &-title {
      @apply font-roboto-condensed font-bold;

      font-size: calcrem(30px);
      line-height: calcrem(40px);
    }

    &-subheading-1 {
      @apply font-roboto-condensed font-bold;

      font-size: calcrem(20px);
      line-height: calcrem(28px);
      letter-spacing: calcrem(0.3px);
    }

    &-subheading-2 {
      @apply font-roboto-condensed font-bold;

      font-size: calcrem(24px);
      line-height: calcrem(32px);
    }

    &-body-1 {
      @apply font-roboto font-normal;

      font-size: calcrem(16px);
      line-height: calcrem(24px);
    }

    &-body-2 {
      @apply font-roboto font-bold;

      font-size: calcrem(16px);
      line-height: calcrem(24px);
    }

    &-caption {
      @apply font-roboto font-normal;

      font-size: calcrem(13px);
      line-height: calcrem(24px);
    }

    &-button {
      @apply font-roboto-condensed font-bold;

      font-size: calcrem(20px);
      line-height: calcrem(20px);
      letter-spacing: calcrem(0.5px);
    }

    &-input {
      @apply font-roboto-condensed font-bold;

      font-size: calcrem(16px);
      line-height: calcrem(18px);
    }

    &-condensed {
      &-headline {
        @apply font-carglass-condensed font-normal;

        font-size: calcrem(56px);
        line-height: calcrem(56px);
      }

      &-title {
        @apply font-roboto-condensed font-bold;

        font-size: calcrem(30px);
        line-height: calcrem(54px);
      }

      &-subheading-1 {
        @apply font-roboto-condensed font-bold;

        font-size: calcrem(20px);
        line-height: calcrem(28px);
        letter-spacing: 0 calcrem(2px);
      }

      &-subheading-2 {
        @apply font-roboto-condensed font-bold;

        font-size: calcrem(24px);
        line-height: calcrem(32px);
      }

      &-body-1 {
        @apply font-roboto-condensed font-normal;

        font-size: calcrem(16px);
        line-height: calcrem(24px);
      }

      &-body-2 {
        @apply font-roboto-condensed font-bold;

        font-size: calcrem(16px);
        line-height: calcrem(24px);
      }

      &-caption {
        @apply font-roboto-condensed font-normal;

        font-size: calcrem(13px);
        line-height: calcrem(20px);
      }

      &-button {
        @apply font-roboto-condensed font-bold;

        font-size: calcrem(20px);
        line-height: calcrem(32px);
        letter-spacing: calcrem(0.5px);
      }

      &-input {
        @apply font-roboto-condensed font-bold;

        font-size: calcrem(16px);
        line-height: calcrem(18px);
      }
    }

    &-mobile {
      &-headline {
        @apply font-carglass-condensed font-normal;

        font-size: calcrem(35px);
        line-height: calcrem(40px);
      }

      &-title {
        @apply font-roboto-condensed font-bold;

        font-size: calcrem(26px);
        line-height: calcrem(32px);
      }

      &-subheading-1 {
        @apply font-roboto-condensed font-bold;

        font-size: calcrem(18px);
        line-height: calcrem(24px);
        letter-spacing: calcrem(0.3px);
      }

      &-subheading-2 {
        @apply font-roboto-condensed font-bold;

        font-size: calcrem(22px);
        line-height: calcrem(32px);
      }

      &-body-1 {
        @apply font-roboto font-normal;

        font-size: calcrem(15px);
        line-height: calcrem(24px);
      }

      &-body-2 {
        @apply font-roboto font-bold;

        font-size: calcrem(16px);
        line-height: calcrem(24px);
      }

      &-caption {
        @apply font-roboto font-normal;

        font-size: calcrem(13px);
        line-height: calcrem(20px);
      }

      &-button {
        @apply font-roboto-condensed font-bold;

        font-size: calcrem(16px);
        line-height: calcrem(24px);
        letter-spacing: calcrem(0.5px);
      }

      &-input {
        @apply font-roboto-condensed font-bold;

        font-size: calcrem(16px);
        line-height: calcrem(18px);
      }
    }
  }
}
