/* You can add global styles to this file, and also import other style files */
@forward "core";
@forward "../../../libs/shared-styles/styles/tailwind";
@forward "../../../libs/shared-styles/styles/overlay";

:root {
  --olb-primary-color: #e60f04;
  --olb-primary-selected-color: #ffdc00;
  --olb-secondary-color: #ce0d03;
}
