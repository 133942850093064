/* stylelint-disable declaration-no-important, annotation-no-unknown */

$cg-white: #fff !default;
$cg-gray-light: #cfcfc8 !default;
$cg-gray-light-70: #f1f1f1 !default;
$cg-gray-light-80: #fafafa !default;
$cg-black: #000 !default;
$cg-black-20: #2e2e2e !default;
$cg-black-30: #444 !default;
$cg-anthracite: #333e46 !default;
$cg-anthracite-light-30: #6c7176 !default;
$cg-anthracite-light-70: #bdc0c3 !default;
$cg-anthracite-light-80: #cecece !default;
$cg-anthracite-light-90: #ececec !default;
$cg-red: #e60f04 !default;
$cg-red-dark-20: #b80c03 !default;
$cg-yellow: #ffdc00 !default;
$cg-yellow-dark-15: #d9bb00 !default;
$cg-yellow-light-95: #fffdf2 !default;
$cg-green: #0c9d6e !default;
$cg-green-dark-15: #0a855e !default;
