/*stylelint-disable*/
/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src:
    local(""),
    url("/assets/fonts/roboto-v20-latin/roboto-v20-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/roboto-v20-latin/roboto-v20-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("/assets/fonts/roboto-v20-latin/roboto-v20-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("/assets/fonts/roboto-v20-latin/roboto-v20-latin-regular.svg#Roboto") format("svg"); /* Legacy iOS */
  font-display: swap;
}

/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src:
    local(""),
    url("/assets/fonts/roboto-v20-latin/roboto-v20-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/roboto-v20-latin/roboto-v20-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("/assets/fonts/roboto-v20-latin/roboto-v20-latin-700.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("/assets/fonts/roboto-v20-latin/roboto-v20-latin-700.svg#Roboto") format("svg"); /* Legacy iOS */
  font-display: swap;
}

/* roboto-condensed-regular - latin */
@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/roboto-condensed-v16-latin/roboto-condensed-v16-latin-regular.eot");
  src:
    local("Roboto Condensed"),
    local("RobotoCondensed-Regular"),
    url("/assets/fonts/roboto-condensed-v16-latin/roboto-condensed-v16-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/roboto-condensed-v16-latin/roboto-condensed-v16-latin-regular.woff2") format("woff2"),
    url("/assets/fonts/roboto-condensed-v16-latin/roboto-condensed-v16-latin-regular.woff") format("woff"),
    url("/assets/fonts/roboto-condensed-v16-latin/roboto-condensed-v16-latin-regular.ttf") format("truetype"),
    url("/assets/fonts/roboto-condensed-v16-latin/roboto-condensed-v16-latin-regular.svg#RobotoCondensed") format("svg");
  font-display: swap;
}

/* roboto-condensed-700 - latin */
@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/roboto-condensed-v16-latin/roboto-condensed-v16-latin-700.eot");
  src:
    local("Roboto Condensed Bold"),
    local("RobotoCondensed-Bold"),
    url("/assets/fonts/roboto-condensed-v16-latin/roboto-condensed-v16-latin-700.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/roboto-condensed-v16-latin/roboto-condensed-v16-latin-700.woff2") format("woff2"),
    url("/assets/fonts/roboto-condensed-v16-latin/roboto-condensed-v16-latin-700.woff") format("woff"),
    url("/assets/fonts/roboto-condensed-v16-latin/roboto-condensed-v16-latin-700.ttf") format("truetype"),
    url("/assets/fonts/roboto-condensed-v16-latin/roboto-condensed-v16-latin-700.svg#RobotoCondensed") format("svg");
  font-display: swap;
}

/* carglass-condensed-regular */
@font-face {
  font-family: "Carglass Condensed";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/CarglassCondensed/CarglassCondensed-Regular.eot");
  src:
    local("Carglass Condensed"),
    local("CarglassCondensed-Regular"),
    url("/assets/fonts/CarglassCondensed/CarglassCondensed-Regular.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/CarglassCondensed/CarglassCondensed-Regular.woff2") format("woff2"),
    url("/assets/fonts/CarglassCondensed/CarglassCondensed-Regular.woff") format("woff"),
    url("/assets/fonts/CarglassCondensed/CarglassCondensed-Regular.ttf") format("truetype");
  font-display: swap;
}
