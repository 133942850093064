@use "../../core/ui/styles/fonts";
@use "../../core/ui/styles/variables/fonts" as font;
@use "../../core/ui/styles/variables/colors" as *;
@use "../../core/ui/styles/extends/typography" as *;
@use "material";
@use "../../core/ui/styles/variables/base" as *;

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: env(safe-area-inset-top, 0) env(safe-area-inset-right, 0) env(safe-area-inset-bottom, 0)
    env(safe-area-inset-left, 0);
  font-family: font.$roboto;
}

table {
  border-spacing: 0;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

h1 {
  @extend %heading-level-1;
}

h2 {
  @extend %heading-level-2;
}

h3 {
  @extend %heading-level-3;
}

h4 {
  @extend %heading-level-4;
}

a {
  color: $cg-red;
  text-decoration: none;
  cursor: pointer;
}

ul {
  margin: 0;
  list-style: none;
  padding-inline-start: 0;
}

p {
  margin: 0 0 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.icon-white {
  svg * {
    stroke: white;
  }
}

.primary-color {
  color: var(--olb-primary-color);
}

.pac-container {
  z-index: $cdk-overlay-container-z-index + 1;
}

.panel-from-bottom {
  width: 100%;
}

.h-fill-available {
  height: 100vh;

  /* stylelint-disable-next-line media-feature-name-no-vendor-prefix */
  @media only screen and (-webkit-min-device-pixel-ratio: 2) {
    /* stylelint-disable-next-line value-no-vendor-prefix */
    @supports (-webkit-overflow-scrolling: touch) {
      /* stylelint-disable-next-line media-feature-name-no-vendor-prefix, value-no-vendor-prefix */
      height: -webkit-fill-available;
    }
  }
}

/* 
  Fix needed for correct height calculation on mobile devices with status bar.
  Some devices and mobile browser seem to not work properly with fill-available so
  we need a fallback solution with viewport height calculated in javascript
*/
.max-h-fill-available {
  max-height: calc(var(--vh, 1vh) * 100); // --vh is set in olb-base-component

  /* stylelint-disable-next-line media-feature-name-no-vendor-prefix */
  @media only screen and (-webkit-min-device-pixel-ratio: 2) {
    @supports (-webkit-overflow-scrolling: touch) {
      /* stylelint-disable-next-line value-no-vendor-prefix */
      max-height: -webkit-fill-available;
    }

    @supports (max-height: fill-available) {
      max-height: fill-available;
    }
  }
}
