@use "../functions/calcrem" as *;
@use "../mixins/breakpoints" as *;
@use "../variables/colors" as *;
@use "../variables/fonts" as font;

%heading {
  color: $cg-anthracite;
  hyphens: manual;
}

%heading-level-1 {
  @extend %heading;

  font-size: calcrem(56px);
  font-family: font.$carglass-condensed;
  line-height: calcrem(56px);

  @include mobile {
    font-size: calcrem(35px);
    font-family: font.$carglass-condensed;
    line-height: calcrem(40px);
  }
}

%heading-level-2 {
  @extend %heading;

  font-weight: 700;
  font-size: calcrem(30px);
  font-family: font.$roboto-condensed;
  line-height: calcrem(40px);

  @include mobile {
    font-weight: 700;
    font-size: calcrem(26px);
    font-family: font.$roboto-condensed;
    line-height: calcrem(32px);
  }
}

%heading-level-3 {
  @extend %heading;

  font-weight: 700;
  font-size: calcrem(24px);
  font-family: font.$roboto-condensed;
  line-height: calcrem(32px);

  @include mobile {
    font-weight: 700;
    font-size: calcrem(22px);
    font-family: font.$roboto-condensed;
    line-height: calcrem(32px);
  }
}

%heading-level-4 {
  @extend %heading;

  font-weight: 700;
  font-size: calcrem(20px);
  font-family: font.$roboto-condensed;
  line-height: calcrem(28px);
  letter-spacing: calcrem(0.3px);

  @include mobile {
    font-weight: 700;
    font-size: calcrem(18px);
    font-family: font.$roboto-condensed;
    line-height: calcrem(24px);
    letter-spacing: calcrem(0.3px);
  }
}

%paragraph {
  color: $cg-anthracite;
  font-size: calcrem(16px);
  font-family: font.$roboto;
  line-height: calcrem(24px);
}

%paragraph-hint {
  @extend %paragraph;

  color: $cg-anthracite-light-30;
  font-size: calcrem(13px);
  font-family: font.$roboto;
  line-height: calcrem(20px);

  @include mobile {
    font-size: calcrem(12px);
    line-height: calcrem(20px);
  }
}

%unordered-list {
  margin: calcrem(16px) 0;
  padding-left: calcrem(32px);
  list-style-position: outside;
  list-style-type: disc;
}
